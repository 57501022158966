<template>
  <div>
    <ValidationObserver
      v-slot="{ handleSubmit, invalid }"
      ref="userNotificationForm"
    >
      <form
        @submit.prevent="handleSubmit(onFormSubmit)"
        class="userNotificationForm narrow-form1"
      >
        <div v-if="loading" class="col-md-12 d-flex justify-content-center py-5">
          <atom-spinner
            slot="loading"
            :animation-duration="1500"
            :size="50"
            color="#3578c6"
          />
        </div>
        <template v-else>
          <div class="row">
            <div class="col-md-12">
              <text-input name="First Name" v-model="formData.first_name" label="First Name" validate="required" />
            </div>
            <div class="col-md-12">
              <text-input name="Last Name" v-model="formData.last_name" label="Last Name" validate="required" />
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-9">
                  <phone-number-input ref="phoneInput" name="Phone" label="call forward number" v-model="formData.phone" :required="false"/>
                </div>
                <div class="col-md-3 col-sm-12">
                  <text-input name="extension" label="Extension" placeholder="EXT number" tooltip="You can enter Extension Number here. ( Max 5 digits ). Leave it blank if there isn't any" :validate="{regex: /^\d{1,5}$/}" v-model="formData.extension" class='voice-extension' />
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <textarea-input
                name="Signature"
                label="Signature"
                max-length="255"
                :rows="3"
                v-model="formData.signature"
              />
            </div>
          </div>
          <div v-if="showNotificationSection" class="row mt-1">
            <div class="col-md-12 mt-3 mb-4">
              <h5 class="text-primary font-weight-bold">Notification</h5>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 d-flex align-items-center">
                  <div class="flex-fill">
                    <phone-number-input ref="phoneInput" name="Phone" label="phone number" v-model="formData.sms_phone" :required="false"/>
                  </div>
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.sms_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <div class="flex-fill">
                    <text-input type="email" name="email" placeholder="Enter email" v-model="formData.email" label="Email" validate="required|email"/>
                  </div>
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.email_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.new_assign_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get notification when the lead is transferred to you even when email  / sms notifications are turned off. <br/>This way you only get notified when a lead is assigned to you.</b>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.push_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get push notification to your mobile app.</b>
                  </div>
                </div>
                <div v-if="showNewMessageNotification" class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.new_message_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get notification from contacts with no agent assigned.</b>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.business_hours_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Keep all notifications out of business hours and send them all together when business hour starts</b>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.taks_reminder_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get notification for task reminder before</b>
                  </div>
                </div>
                <div v-if="formData.taks_reminder_notification" class="col-md-12 mt-4 ml-5">
                  <div class="row">
                    <div class="col-md-3 mt-4">
                      <div class="form-group">
                        <div class="input-group">
                          <label class="control-label">Duration</label>
                          <text-input type="number" name="duration" v-model="formData.taks_reminder_duration" :validate="'required'" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <SelectInput label="" v-model="taskReminderDurationPedriod" name="durationPeriod" :options="duratioOptions" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5">
              <p class="text-primary font-weight-bold">Notifications are set to be sent out by default 24 hours a day, if you want notification during a set hours uncheck 24 hours and set your desired notification hours.</p>
              <div class="hours mt-4">
                <div
                  class="row align-items-center"
                  v-for="(obj, index) in formData.hours"
                  :key="index"
                >
                  <div class="col-1">
                    <strong>{{ obj.weekDay.toUpperCase() }}</strong>
                  </div>
                  <div class="col">
                    <time-selector
                      v-model="obj.openTime"
                      label="Open"
                      :disabled="obj.fullOpen"
                    />
                  </div>
                  <div class="col">
                    <time-selector
                      v-model="obj.closeTime"
                      label="Close"
                      :disabled="obj.fullOpen"
                    />
                  </div>
                  <div class="col">
                    <div class="checkbox abc-checkbox abc-checkbox-primary">
                      <input
                        type="checkbox"
                        name="schedule"
                        :id="`country-group-${index}`"
                        value="Daily"
                        v-model="obj.fullOpen"
                      />
                      <label :for="`country-group-${index}`">
                        <span class="abc-label-text">24 hours</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-2">
                    <vuestic-switch
                      v-model="obj.open"
                      class="align-middle"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">Open</span>
                      <span slot="falseTitle">Closed</span>
                    </vuestic-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12 d-flex justify-content-center">
              <button
                class="btn btn-primary"
                :disabled="invalid"
                style="min-width: 80px"
              >
                <atom-spinner
                  v-if="processing"
                  slot="loading"
                  :animation-duration="1500"
                  :size="14"
                  color="#FFF"
                  style="margin: auto"
                />
                <span v-else>Update</span>
              </button>
            </div>
          </div>
        </template>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TimeSelector from "../../forms/TimeSelector";

export default {
  name: "notification",
  components: {
    TimeSelector,
  },
  data() {
    return {
      formData: {
        first_name: '',
        last_name: '',
        phone: '',
        extension: '',
        sms_phone: '',
        email: '',
        email_notification: false,
        sms_notification: false,
        notification_full_time: true,
        notification_start_time: '09:00AM',
        notification_end_time: '05:00PM',
        new_assign_notification: true,
        push_notification: true,
        new_message_notification: true,
        business_hours_notification: false,
        taks_reminder_notification: true,
        taks_reminder_duration: 1,
        taks_reminder_duration_period: 'hour',
        hours: [],
      },
      taskReminderDurationPedriod: undefined,
    };
  },
  mounted() {
    this.formData = {
      ...this.settings,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone: this.user.phone,
      extension: this.user.extension,
      sms_phone: this.user.sms_phone,
      email: this.user.email,
      new_assign_notification: this.user.settings.new_assign_notification,
      push_notification: this.user.settings.push_notification,
      new_message_notification: this.user.settings.new_message_notification,
      business_hours_notification: this.user.settings.business_hours_notification,
      taks_reminder_notification: this.user.settings.taks_reminder_notification,
      taks_reminder_duration: this.user.settings.taks_reminder_duration,
      taks_reminder_duration_period: this.user.settings.taks_reminder_duration_period,
      signature: this.user.signature,
    }
    this.taskReminderDurationPedriod = this.duratioOptions.find(item => item.value == this.user.settings.taks_reminder_duration_period)
  },
  computed: {
    ...mapGetters("auth", {
      settings: "settings",
      user: "user"
    }),
    ...mapState("auth", {
      loading: "loading",
      processing: "processing",
    }),
    duratioOptions() {
      return [
      { value: 'seconds', label: "seconds" },
      { value: 'minutes', label: "minutes" },
      { value: 'hour', label: "hour(s)" },
      { value: 'day', label: "day(s)" },
      { value: 'week', label: "week(s)" },
      { value: 'month', label: "month(s" },
      { value: 'year', label: "year(s)" },
      ]
    },
    showNotificationSection() {
      return this.user && (['2', '3', '4'].includes(this.user.user_type) || this.user.user_type == "")
    },
    showNewMessageNotification() {
      return this.user && (['3', '4'].includes(this.user.user_type))
    }
  },
  methods: {
    onFormSubmit() {
      const params = {
        ...this.formData,
        taks_reminder_duration_period: this.taskReminderDurationPedriod ? this.taskReminderDurationPedriod.value : null,
      };
      this.$store
        .dispatch("auth/updateSettings", params)
        .catch((err) => {
          let errors = err.response.data.errors
          console.log(errors)
          if (errors)
          {
            this.$refs.userNotificationForm.setErrors(errors)
          }
        })
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

      